const Footer = () => {
    return (
        <div className="footer">
            <div id="copyright">&copy; 2024 Ben Kisow</div>
            <div className="footer-links">
                <a href="/">Home</a>
                <a href="/about-me">About Me</a>
                <a href="/contact-me">Contact Me</a>
            </div>  
        </div>
    )
}

export default Footer;