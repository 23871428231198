import { useEffect, useState } from 'react';
import Project from '../components/Project';
import Skill from '../components/Skill';
import Footer from '../components/Footer';

const Home = () => {
    const [skills, setSkills] = useState();
    const [projects, setProjects] = useState();
    const [col41, setCol41] = useState();
    const [col42, setCol42] = useState();
    const [col43, setCol43] = useState();
    const [col44, setCol44] = useState();
    const [col21, setCol21] = useState();
    const [col22, setCol22] = useState();
    const [numColumns, setNumColumns] = useState(4);
    const [smallNav, setSmallNav] = useState(false);
    const [navTop, setNavTop] = useState(false);

    useEffect(() => {
        navMediaQuery()
        columnMediaQuery()

        const fetchSkills = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/skills`)
            const json = await response.json()
            
            if (response.ok) {
                setSkills(json)
            }
        }

        const fetchProjects = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/projects`)
            const json = await response.json()

            const sortFunction = (a, b) => {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;

                if (a.column > b.column) return 1;
                if (a.column < b.column) return -1;
            }

            const projects = json.filter((project) => project.isVisible == 1).sort(sortFunction)

            const col41 = projects.filter((project) => project.column == 1).sort(sortFunction)
            const col42 = projects.filter((project) => project.column == 2).sort(sortFunction)
            const col43 = projects.filter((project) => project.column == 3).sort(sortFunction)
            const col44 = projects.filter((project) => project.column == 4).sort(sortFunction)

            const col21 = projects.filter((project) => (project.column == 1 || project.column == 2)).sort(sortFunction)
            const col22 = projects.filter((project) => (project.column == 3 || project.column == 4)).sort(sortFunction)

            if (response.ok) {
                setProjects(projects)

                setCol41(col41)
                setCol42(col42)
                setCol43(col43)
                setCol44(col44)

                setCol21(col21)
                setCol22(col22)
            }
        }

        fetchSkills();
        fetchProjects();
    }, [])

    const expand = () => {
        const header = document.getElementById("home-header");
        const nav = document.getElementById("main-nav");
        const content = document.getElementById("content");
        const expand = document.getElementById("expand");

        if (expand.innerText === "expand_more") {
            window.scrollTo(0,0)
            header.classList.add("hide");
            header.style.zIndex = 10;
            nav.classList.remove("bottom");
            nav.classList.add("top");
            content.classList.add("show");
            content.classList.remove("hide");
            content.style.zIndex = 15;
            expand.innerText = "expand_less"
            setNavTop(true)
            return
        }
        if (expand.innerText === "expand_less") {
            header.classList.remove("hide");
            header.style.zIndex = 15;
            nav.classList.add("bottom");
            nav.classList.remove("top");
            content.classList.remove("show");
            content.classList.add("hide");
            content.style.zIndex = 10;
            expand.innerText = "expand_more"
            setNavTop(false)
            return
        }
    }   

    const twoCol = window.matchMedia("(max-width: 1600px)")
    const oneCol = window.matchMedia("(max-width: 800px)")

    const smallNavQ = window.matchMedia("(max-width: 600px)")

    const columnMediaQuery = () => {
        if (oneCol.matches) {
            setNumColumns(1)
        } else if (twoCol.matches) {
            setNumColumns(2)
        } else  {
            setNumColumns(4)
        }
    }

    const navMediaQuery = () => {
        if (smallNavQ.matches) {
            setSmallNav(true)
        } else {
            setSmallNav(false)
        }
    }

    twoCol.addEventListener("change", function() {
        columnMediaQuery()
    })

    oneCol.addEventListener("change", function() {
        columnMediaQuery()
    })

    smallNavQ.addEventListener("change", function() {
        navMediaQuery()
    })

    const toggleSkillExpand = () => {
        const ele = document.getElementById("skillExpand");
        
        if (ele.innerText == "expand_more") {
            ele.innerText = "expand_less"
        } else {
            ele.innerText = "expand_more"
        }
    }

    const toggleProjectExpand = () => {
        const ele = document.getElementById("projectExpand");
        
        if (ele.innerText == "expand_more") {
            ele.innerText = "expand_less"
        } else {
            ele.innerText = "expand_more"
        }
    }

    return ( 
        <div className="Home">
            <div id="home-header">
                {/* <img src="/images/background.png" alt="background"/> */}
                <div id="home-header-words-container">
                    <span id="welcome">Hi, I'm Ben.</span>
                    <br/>
                    <span id="intro">I'm a developer specializing in apps, cloud, and data.</span>
                </div>     
            </div>

            {smallNav == false && (
                <nav id="main-nav" className={`${navTop ? 'top' : 'bottom'}`}>
                    <ul>
                        <li><a href="/about-me">about me</a></li>
                        <li><span className="material-symbols-outlined" id="expand" onClick={expand}>
                            {`${navTop ? 'expand_less' : 'expand_more'}`}
                        </span></li>
                        <li><a href="/contact-me">contact me</a></li>
                    </ul>
                </nav>
            )}
            {smallNav == true && (
                <nav id="main-nav" className={`${navTop ? 'top' : 'bottom'}`}>
                    <ul>
                        <li><span className="material-symbols-outlined" id="expand" onClick={expand}>
                            {`${navTop ? 'expand_less' : 'expand_more'}`}
                        </span></li>
                        <li>
                            <div className="dropdown">
                                <button className="btn border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="material-symbols-outlined" id="menu">
                                        menu
                                    </span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/about-me">about me</a></li>
                                    <li><a className="dropdown-item" href="/contact-me">contact me</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </nav>
            )}

            <div id="content" className="hide">
                <h1>
                    Skills
                    <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#skills" aria-expanded="false" aria-controls="skills" onClick={toggleSkillExpand}>
                        <span className="material-symbols-outlined" id="skillExpand">
                            expand_less
                        </span>
                    </button>
                </h1>
                
                <div className="skills collapse show" id="skills">
                    {skills && skills.map((skill) => (
                        <Skill key={ skill._id } skill={ skill } />
                    ))}
                </div>
                
                <h1>
                    Projects
                    <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#projects-container" aria-expanded="false" aria-controls="projects-container" onClick={toggleProjectExpand}>
                        <span className="material-symbols-outlined" id="projectExpand">
                            expand_less
                        </span>
                    </button>
                </h1>
                    {numColumns == 4 && (
                        <div className="projects-container collapse show" id="projects-container">
                            <div className="column">
                                {col41 && col41.map((project) => (
                                    <Project key={ project._id } project={ project } />
                                ))}
                            </div>
                            <div className="column">
                                {col42 && col42.map((project) => (
                                    <Project key={ project._id } project={ project } />
                                ))}
                            </div>
                            <div className="column">
                                {col43 && col43.map((project) => (
                                    <Project key={ project._id } project={ project } />
                                ))}
                            </div>
                            <div className="column">
                                {col44 && col44.map((project) => (
                                    <Project key={ project._id } project={ project } />
                                ))}
                            </div>
                        </div>
                    )}
                    {numColumns == 2 && (
                        <div className="projects-container collapse show" id="projects-container">
                        <div className="column">
                            {col21 && col21.map((project) => (
                                <Project key={ project._id } project={ project } />
                            ))}
                        </div>
                        <div className="column">
                            {col22 && col22.map((project) => (
                                <Project key={ project._id } project={ project } />
                            ))}
                        </div>
                    </div>
                    )}
                    {numColumns == 1 && (
                        <div className="projects-container collapse show" id="projects-container">
                        <div className="column">
                            {projects && projects.map((project) => (
                                <Project key={ project._id } project={ project } />
                            ))}
                        </div>
                    </div>
                    )}
            <Footer />
            </div>
        </div>
     );
}
 
export default Home;