import { useState, useEffect } from 'react'
import Footer from '../components/Footer'

const About = () => {
    const [smallNav, setSmallNav] = useState(false);
    const [smallResumeItem, setSmallResumeItem] = useState(false);

    const smallNavQ = window.matchMedia("(max-width: 480px)")
    const smallResumeItemQ = window.matchMedia("(max-width: 1000px)")

    useEffect(() => {
        document.title = "Ben Kisow Portfolio - About Me"

        navMediaQuery()
        resumeItemMediaQuery()
    },[])

    const navMediaQuery = () => {
        if (smallNavQ.matches) {
            setSmallNav(true)
        } else {
            setSmallNav(false)
        }
    }

    const resumeItemMediaQuery = () => {
        if (smallResumeItemQ.matches) {
            setSmallResumeItem(true)
        } else {
            setSmallResumeItem(false)
        }
    }

    smallNavQ.addEventListener("change", function() {
        navMediaQuery()
    })

    smallResumeItemQ.addEventListener("change", function() {
        resumeItemMediaQuery()
    })

    const toggleButton = (id) => {
        const button = document.getElementById(id);
        if (button.innerText == "More") {
            button.innerText = "Less"
        } else {
            button.innerText = "More"
        }
    }

    return ( 
        <div className="about-me">
            {smallNav == false && (
                <nav id="main-nav" className="top">
                    <ul>
                        <li><a href="/">home</a></li>
                        <li><a href="/contact-me">contact me</a></li>
                    </ul>
                </nav>
            )}
            {smallNav == true && (
                <nav id="main-nav" className="top">
                    <ul style={{float:"right"}}>
                        <li>
                            <div className="dropdown">
                                <button className="btn border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="material-symbols-outlined" id="menu">
                                        menu
                                    </span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/">home</a></li>
                                    <li><a className="dropdown-item" href="/contact-me">contact me</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </nav>
            )}

            <div className="main-container">
                <span id="about-title">About Me</span>

                <br/>
                <div className="about-section">
                    <img src="images/Me.jpg" alt="Me" id="me-img"></img>
                    <div id="me-par">
                        <div className="info-card">
                            <span className="material-symbols-outlined info-card-icon">
                                person
                            </span>
                            <span>
                                Ben Kisow
                            </span>
                        </div>
                        <div className="info-card">
                            <span className="material-symbols-outlined info-card-icon">
                                pin_drop
                            </span>
                            <span>
                                Chicago, IL
                            </span>
                        </div>
                        <br/>
                        <p>As a developer, I have a strong passion for creating fully custom web applications from scratch. My go-to tools include the MERN stack (MongoDB, Express.js, React.js, Node.js) and .NET with Blazor, but I am always learning more.</p>
                        <p>I am currently working as a Consultant at Baker Tilly US, helping our clients make the most of their data. I enjoy finding ways to write clean and efficient code that can be easily understood and maintained.</p>
                        <p>Go <a className='accent-link' href="/">back to home</a> to check out my personal projects. Also, feel free to <a className='accent-link' href="/contact-me">contact me</a> anytime.</p>
                        <p>Thanks for visiting!</p>
                    </div>      
                </div>
                <br/>

                <h1>Professional Experience</h1>
                <div className="resume-item">
                    <h2>Consultant - Applications, Cloud, and Data</h2>
                    <img src="images/resume/baker-tilly.png" alt="baker-tilly" className="resume-img"/>
                    <h3><strong>Baker Tilly</strong>, Chicago, IL</h3>        
                    <h4><span className="material-symbols-outlined">
                        date_range
                    </span> Aug 2022 - Present</h4>
                    {smallResumeItem == false && 
                        <div>
                            <br/>
                            <ul>
                                <li>Connect with clients daily to gather requirements, design solutions, and support user acceptance testing</li>
                                <li>Collaborate with an Agile development team to create and maintain an Azure Managed SQL database with over 150 tables and 100 stored procedures, including robust technical documentation</li>
                                <li>Utilize Azure, SQL, and SSIS to develop a fully automated ETL process which takes 34 unique inputs, outputting over ten GL Journal Entries per month and reducing prior processing time by at least 90%</li>
                                <li>Designed and implemented a built-in process for error logging, error handling, and data quality checks with SQL and SSIS</li>
                                <li>Devote 5 hours per week to skill advancement in web development, full-stack JavaScript, .NET, Blazor, Python, and cloud computing</li>
                                <li>Actively participate in the Application Development Community of Practice, attending weekly standups, contributing to a knowledge notebook, and doing projects in .NET and Blazor</li>
                                <li>Contribute to campus recruiting efforts by attending career fairs, networking with students, and supporting onboarding</li>
                            </ul>
                        </div>
                    }
                    {smallResumeItem == true && 
                        <div>
                            <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#baker-tilly" aria-expanded="false" aria-controls="baker-tilly" id="BT-Button" onClick={() => toggleButton("BT-Button")}>More</button>
                            <div className="collapse" id="baker-tilly">
                                <br/>
                                <ul>
                                    <li>Connect with clients daily to gather requirements, design solutions, and support user acceptance testing</li>
                                    <li>Collaborate with an Agile development team to create and maintain an Azure Managed SQL database with over 150 tables and 100 stored procedures, including robust technical documentation</li>
                                    <li>Utilize Azure, SQL, and SSIS to develop a fully automated ETL process which takes 34 unique inputs, outputting over ten GL Journal Entries per month and reducing prior processing time by at least 90%</li>
                                    <li>Designed and implemented a built-in process for error logging, error handling, and data quality checks with SQL and SSIS</li>
                                    <li>Devote 5 hours per week to skill advancement in web development, full-stack JavaScript, .NET, Blazor, Python, and cloud computing</li>
                                    <li>Actively participate in the Application Development Community of Practice, attending weekly standups, contributing to a knowledge notebook, and doing projects in .NET and Blazor</li>
                                    <li>Contribute to campus recruiting efforts by attending career fairs, networking with students, and supporting onboarding</li>
                                </ul>
                            </div>
                        </div>
                    }
                    
                </div>
                <div className="resume-item">
                    <h2>Global Supply Chain Associate</h2>
                    <img src="images/resume/rockline.png" alt="rockline" className="resume-img"/>
                    <h3><strong>Rockline Industries</strong>, Sheboygan, WI</h3>
                    <h4><span className="material-symbols-outlined">
                        date_range
                    </span> May 2021 - Dec 2021</h4>
                    {smallResumeItem == false &&
                        <div>
                            <br/>
                            <ul>
                                <li>Leveraged Microsoft Excel and SQL to create and maintain a sustainable and reliable tool that predicts warehouse capacity needs over the next 18 months based on sales forecast and manufacturing policies, which is used for distribution planning and development of inventory goals</li>
                                <li>Automated the running process of three reports, utilizing Visual Basic for Applications and Task Scheduler</li>
                                <li>Developed an automated framework in Microsoft Excel to identify potential shortages or over-stocking of products</li>
                                <li>Utilized Power BI to create a dashboard which visualizes service metrics for four outside warehouses, using live data to highlight current or potential complications in service level as well as balanced scorecard performance</li>
                                <li>Designed a just-in-time spreadsheet model for planning the supply of four raw materials from a Kanban lot based on run rates, shift schedules, customer demand, and the established minimums and maximums of the Kanban lots</li>
                                <li>Completed a monthly inventory review, using J.D. Edwards to investigate products with more than 365 days on hand, working with the Sales, Demand Planning, and Inventory and Replenishment teams to prevent or mitigate over-stocking of products</li>
                                <li>Provided support to the Replenishment Coordinator, receiving inbound receipts into the ports of Seattle, Houston, and Newark, as well as creating outbound transfers from the ports to various warehouses</li>
                                <li>Expanded and managed an advanced supply plan in Excel, which was used by the Sales, Inventory and Replenishment, and Executive teams to oversee order fulfillment capabilities for key customers</li>
                                <li>Studied various safety stock methods and provided the Inventory and Replenishment Manager with recommendations for using safety stock strategy to optimize inventory holding costs and service levels</li>
                            </ul>
                        </div>
                    }
                    {smallResumeItem == true && 
                        <div>
                            <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#rockline" aria-expanded="false" aria-controls="rockline" id="Rockline-Button" onClick={() => toggleButton("Rockline-Button")}>More</button>
                            <div className="collapse" id="rockline">
                                <br/>
                                <ul>
                                    <li>Leveraged Microsoft Excel and SQL to create and maintain a sustainable and reliable tool that predicts warehouse capacity needs over the next 18 months based on sales forecast and manufacturing policies, which is used for distribution planning and development of inventory goals</li>
                                    <li>Automated the running process of three reports, utilizing Visual Basic for Applications and Task Scheduler</li>
                                    <li>Developed an automated framework in Microsoft Excel to identify potential shortages or over-stocking of products</li>
                                    <li>Utilized Power BI to create a dashboard which visualizes service metrics for four outside warehouses, using live data to highlight current or potential complications in service level as well as balanced scorecard performance</li>
                                    <li>Designed a just-in-time spreadsheet model for planning the supply of four raw materials from a Kanban lot based on run rates, shift schedules, customer demand, and the established minimums and maximums of the Kanban lots</li>
                                    <li>Completed a monthly inventory review, using J.D. Edwards to investigate products with more than 365 days on hand, working with the Sales, Demand Planning, and Inventory and Replenishment teams to prevent or mitigate over-stocking of products</li>
                                    <li>Provided support to the Replenishment Coordinator, receiving inbound receipts into the ports of Seattle, Houston, and Newark, as well as creating outbound transfers from the ports to various warehouses</li>
                                    <li>Expanded and managed an advanced supply plan in Excel, which was used by the Sales, Inventory and Replenishment, and Executive teams to oversee order fulfillment capabilities for key customers</li>
                                    <li>Studied various safety stock methods and provided the Inventory and Replenishment Manager with recommendations for using safety stock strategy to optimize inventory holding costs and service levels</li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
                <br/>
                <h1>Education</h1>
                <div className="resume-item">
                    <h2>Master of Science: <em>Business Analytics</em></h2>
                    <img src="images/resume/uw.png" alt="uw" className="resume-img"/>
                    <h3><strong>University of Wisconsin-Madison</strong>, Wisconsin School of Business, Madison, WI</h3>
                    <h4><span className="material-symbols-outlined">
                        date_range
                    </span> Aug 2021 - May 2022</h4>
                    {smallResumeItem == false &&
                        <div>
                            <br/>
                            <ul>
                                <li>Key Coursework: Machine Learning, Data Technology (SQL), Data Visualization (Tableau), Statistics and Programming (R & Python), Cloud Computing (AWS, Azure, Google Cloud Platform), Prescriptive Modeling and Optimization (Excel & Python)</li>
                                <li>Analytics Consulting Practicum: Worked on a team to create a central database with visualizations for a Wisconsin company</li>
                            </ul>
                        </div>
                    }
                    {smallResumeItem == true && 
                        <div>
                            <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#uw-master" aria-expanded="false" aria-controls="uw-master" id="UW-Master-Button" onClick={() => toggleButton("UW-Master-Button")}>More</button>
                            <div className="collapse" id="uw-master">
                                <br/>
                                <ul>
                                    <li>Key Coursework: Machine Learning, Data Technology (SQL), Data Visualization (Tableau), Statistics and Programming (R & Python), Cloud Computing (AWS, Azure, Google Cloud Platform), Prescriptive Modeling and Optimization (Excel & Python)</li>
                                    <li>Analytics Consulting Practicum: Worked on a team to create a central database with visualizations for a Wisconsin company</li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
                <div className="resume-item">
                    <h2>Bachelor of Business Administration: <em>Marketing & Supply Chain Management</em></h2>
                    <img src="images/resume/uw.png" alt="uw" className="resume-img"/>
                    <h3><strong>University of Wisconsin-Madison</strong>, Wisconsin School of Business, Madison, WI</h3>
                    <h4><span className="material-symbols-outlined">
                        date_range
                    </span> Aug 2018 - May 2021</h4>
                    {smallResumeItem == false &&
                        <div>
                            <br/>
                            <ul>
                                <li>GPA: 3.94/4.00 (Dean's List x6)</li>
                            </ul>
                        </div>
                    }
                    {smallResumeItem == true && 
                        <div>
                            <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#uw-bba" aria-expanded="false" aria-controls="uw-bba" id="UW-BBA-Button" onClick={() => toggleButton("UW-BBA-Button")}>More</button>
                            <div className="collapse" id="uw-bba">
                                <br/>
                                <ul>
                                    <li>GPA: 3.94/4.00 (Dean's List x6)</li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
     );
}
 
export default About;