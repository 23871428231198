import { useState, useEffect } from 'react'

const Skill = ({skill}) => {
    const [narrowScreen, setNarrowScreen] = useState(false);
    const levelArray = [...Array(skill.level)]

    useEffect(() => {
        widthMediaQuery()
    },[])

    const medQ = window.matchMedia("(max-width: 460px)")

    const widthMediaQuery = () => {
        if (medQ.matches) {
            setNarrowScreen(true)
        } else  {
            setNarrowScreen(false)
        }
    }

    medQ.addEventListener("change", function() {
        widthMediaQuery()
    })

    return ( 
        <div>
            {narrowScreen == false && (
                <div className={`skill ${skill.title == 'Digital Ocean' ? 'dig-o' : ''}`}>
                    <h2>{skill.title}</h2>
                    <img src={skill.icon} alt={skill.title}/>
                    <div className="level-bar">
                        {levelArray.map((value, index) => (
                            <div key={index} className="level-fifth filled"></div>
                        ))}
                    </div>
                </div>
            )}
            {narrowScreen == true && (
                <div className={`skill small ${skill.title == 'Digital Ocean' ? 'dig-o' : ''}`}>
                    <h2>{skill.title}</h2>
                    <img src={skill.icon} alt={skill.title}/>
                    <div className="level-bar">
                        {levelArray.map((value, index) => (
                            <div key={index} className="level-fifth filled"></div>
                        ))}
                    </div>
                </div>
            )}
        </div>
     );
}
 
export default Skill;