const NotFound = () => {
    return ( 
        <div className="Home">
            <div id="home-header">
                <span id="welcome">Page Not Found</span>
            </div>
        </div>
     );
}
 
export default NotFound;