import { useEffect, useState } from "react"
import Tech from './Tech'

const Project = ({project}) => {
    const [techDetails, setTechDetails] = useState();

    const projectTechs = project.techs

    useEffect(() => {
        const fetchTechs = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/techs/`)
            const json = await response.json()
    
            if (response.ok) {
                setTechDetails(json.filter((tech) => projectTechs.includes(tech._id)).sort())
            }
        }
    
        fetchTechs();
    }, [])

    return ( 
        <div className="project">
            <h1>{project.title}</h1>
            <p>{project.description}</p>
            <a href={project.link} target="_blank"><img src={project.img} alt={project.title}/></a>
            <div className="techs">
                <span className="techs-label">Technologies Used:</span>
                {techDetails && techDetails.map((tech) => (
                    <Tech key={ tech._id} tech={ tech } />
                ))}
            </div>
        </div>
     );
}
 
export default Project;