import emailjs from 'emailjs-com';
import { useState, useEffect } from 'react'
import Footer from '../components/Footer'

const Contact = () => {
    const [smallNav, setSmallNav] = useState(false);

    const smallNavQ = window.matchMedia("(max-width: 480px)")

    useEffect(() => {
        document.title = "Ben Kisow Portfolio - Contact Me"

        navMediaQuery()
    },[])

    const navMediaQuery = () => {
        if (smallNavQ.matches) {
            setSmallNav(true)
        } else {
            setSmallNav(false)
        }
    }

    smallNavQ.addEventListener("change", function() {
        navMediaQuery()
    })

    var validator = require("email-validator");

    const removeErrorClass = (e) => {
        const form_message = document.getElementById("submit-form-message")

        e.target.classList.remove('error');
        form_message.innerText = "";
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const form_message = document.getElementById("submit-form-message")

        // Validation
        const from_name = e.target["from_name"]
        const subject = e.target["subject"]
        const message = e.target["message"]

        const emptyFields = []
        var goodToSend = 1

        if (from_name.value == "") {
            from_name.classList.add('error');
            emptyFields.push('from_name');
        }
        if (subject.value == "") {
            subject.classList.add('error');
            emptyFields.push('subject');
        }
        if (message.value == "") {
            message.classList.add('error');
            emptyFields.push('message');
        }
        
        if (validator.validate(from_name.value) == false) {
            from_name.classList.add('error');
            form_message.innerText = "Please enter a valid Email";
            goodToSend = 0;
        }

        if (emptyFields.length > 0) {
            form_message.innerText = "Please fill in all the fields";
            goodToSend = 0;
        }

        if (goodToSend == 0) {
            return
        }
        
        form_message.innerText = "Sending..."

        const SERVICE_ID = "service_sfpdzaz";
        const TEMPLATE_ID = "template_uyxjz7f";
        const PUBLIC_KEY = "X4KP3OhobsUYP_KJ5";
        
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
        .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        form_message.innerText = "Message sent"
        e.target.reset()
        }, function(error) {
        console.log('FAILED...', error);
        form_message.innerText = "We couldn't send your message"
        e.target.reset()
        });    
    }

    return ( 
        <div className="contact-me">
            {smallNav == false && (
                <nav id="main-nav" className="top">
                    <ul>
                        <li><a href="/">home</a></li>
                        <li><a href="/about-me">about me</a></li>
                    </ul>
                </nav>
            )}
            {smallNav == true && (
                <nav id="main-nav" className="top">
                    <ul style={{float:"right"}}>
                        <li>
                            <div className="dropdown">
                                <button className="btn border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="material-symbols-outlined" id="menu">
                                        menu
                                    </span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/">home</a></li>
                                    <li><a className="dropdown-item" href="/about-me">about me</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </nav>
            )}

            <div className="main-container">
                <span id="contact-title">Contact Me</span>
                <p>Send me a message:</p>
                <form id="contact-form" onSubmit={handleSubmit}>
                    <label>Your Email:</label><br/><input name="from_name" type="text" onChange={removeErrorClass}></input>
                    <br/>
                    <br/>
                    <label>Subject:</label><br/><input name="subject" type="text" onChange={removeErrorClass}></input>
                    <br/>
                    <br/>
                    <label>Body:</label><br/><textarea name="message" cols="40" rows="10" onChange={removeErrorClass}></textarea>
                    <br/>
                    <br/>
                    <button type="submit">Send</button><span id="submit-form-message"></span>
                </form>
                <br/>
                <p>Connect with me on <a className="accent-link" href="https://www.linkedin.com/in/ben-kisow" target="_blank">LinkedIn</a></p>
            </div>
            <Footer />
        </div>
     );
}
 
export default Contact;