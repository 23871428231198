import { BrowserRouter, Routes, Route } from 'react-router-dom';

// pages
import Home from "./pages/Home";
import TempHome from "./pages/TempHome";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NotFound from './pages/404';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <Routes>
            <Route
              path="/"
              element={<Home/>}
            />
            <Route
              path="/about-me"
              element={<About/>}
            />
            <Route
              path="/contact-me"
              element={<Contact/>}
            />
            <Route
              path="*"
              element={<NotFound/>}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;